import * as React from "react";

import Page from "../components/Page";
import LongText from "../components/LongText";
import DefaultLayout from "../layouts/default";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";
import LeadHeading from "../components/LeadHeading";
import Helmet from "react-helmet";

const Datenschutz = () => (
  <DefaultLayout>
    <Helmet>
      <title>
        Datenschutzerklärung HICC<sup>®</sup>
      </title>
    </Helmet>
    <Page>
      <LongText background={"white"}>
        <Title>Datenschutzerklärung</Title>
        <Paragraph>2. Dezember 2019</Paragraph>
        <Paragraph>
          In dieser Datenschutzerklärung erläutern wir, wie wir Personendaten
          erheben und sonst bearbeiten. Das ist keine abschliessende
          Beschreibung; allenfalls regeln andere Datenschutzerklärungen oder
          allgemeine Geschäftsbedingungen, Teilnahmebedingungen und ähnliche
          Dokumente spezifische Sachverhalte. Unter Personendaten werden alle
          Angaben verstanden, die sich auf eine bestimmte oder bestimmbare
          Person beziehen. Wenn Sie uns Personendaten anderer Personen wie
          Familienmitgliedern, Arbeitskollegen etc. zur Verfügung stellen, gehen
          Sie bitte sicher, dass die Person(en) die vorliegende
          Datenschutzerklärung kennen und teilen Sie uns die Daten nur mit, wenn
          Sie dies dürfen und die Personendaten korrekt sind.
        </Paragraph>
        <LeadHeading>Begrifflichkeiten</LeadHeading>
        <Paragraph>
          <b>Personenbezogene Daten</b>
          <br />
          Personenbezogene Daten sind alle auf eine indentifizierte oder
          identifizierbare natürliche Person bezogene Daten. Als identifizierbar
          wird eine natürliche Person angesehen, die direkt oder indirekt,
          insbesondere mittels Zuordnung zu einer Kennung wie einen Namen,
          Kennnummer, Standortdaten, einem Online-Pseudonym oder zu einem oder
          mehreren besonderen Merkmalen wie der Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen,
          kulturellen oder sozialen Identität dieser Person sind, identifiziert
          werden kann.
          <br />
          <br />
          <b>Betroffene Person</b>
          <br />
          Betroffene Person ist jene identifizierte oder identifizierbare
          natürliche Person, deren personenbezogene Daten von dem für die
          Verarbeitung Verantwortlichen verarbeitet werden.
          <br />
          <br />
          <b>Verarbeitung</b>
          <br />
          Verarbeitung ist jeder mit oder ohne Hilfe automatisierte und/oder
          ausgeführte Vorgang im Zusammenhang mit personenbezogenen Daten wie
          das Auslesen, Abfragen, die Verwendung, Offenlegung durch
          Übermittlung, Verbreitung, oder eine andere Art der Bereitstellung,
          Abgleich oder Verknüpfung, das Löschen oder die Vernichtung.
          Einschränkung der Verarbeitung Einschränkung der Verarbeitung ist die
          Markierung gespeicherter personenbezogener Daten mit dem Ziel, Ihre
          künftige Verarbeitung einzuschränken. <br />
          <br />
          <b>Profiling</b>
          <br />
          Profiling ist jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese
          personenbezogene Daten verwendet werden, um bestimmte persönliche
          Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
          insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher
          Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit,
          Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
          zu analysieren oder vorherzusagen.
          <br />
          <br />
          <b>Pseudonymisierung</b>
          <br /> Pseudonymisierung ist die Verarbeitung personenbezogener Daten
          in einer Weise, auf welche die personenbezogenen Daten ohne
          Hinzuziehung zusätzlicher Informationen nicht mir einer spezifischen
          betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
          Informationen gesondert aufbewahrt werden und technische und
          organisatorische Massnahmen unterliegen, die gewährleisten, dass die
          personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden können.
          <br />
          <br />
          <b>Verantwortlicher oder für die Verarbeitung Verantwortlicher</b>
          <br />
          Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind
          die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
          das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche
          beziehungsweise können die bestimmten Kriterien seiner Benennung nach
          dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
          <br />
          <br />
          <b>Auftragsverarbeiter</b>
          <br /> Auftragsverarbeiter ist eine natürliche oder juristische
          Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene
          Daten im Auftrag des Verantwortlichen verarbeitet. Empfänger Empfänger
          ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
          andere Stelle, der personenbezogene Daten offengelegt werden,
          unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder
          nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags
          nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise
          personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
          <br />
          <br />
          <b>Dritter</b>
          <br /> Dritter ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle außer der betroffenen Person, dem
          Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
          der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
          verarbeiten.
          <br />
          <br />
          <b>Einwilligung</b>
          <br /> Einwilligung ist jede von der betroffenen Person freiwillig für
          den bestimmten Fall in informierter Weise und unmissverständlich
          abgegebene Willensbekundung in Form einer Erklärung oder einer
          sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
          betreffenden personenbezogenen Daten einverstanden ist.
        </Paragraph>
        <LeadHeading>1. Verantwortlicher</LeadHeading>
        <Paragraph>
          Verantwortlich für die Datenbearbeitungen, die wir hier beschreiben,
          ist Prof. Dr. med. Wolfgang Korte, soweit im Einzelfall nichts anderes
          angegeben ist. Wenn Sie datenschutzrechtliche Anliegen haben, können
          Sie uns diese an folgende Kontaktadresse mitteilen:
          <br />
          <br /> Prof. Dr. med. Wolfgang Korte
          <br />
          <br /> Facharzt für Innere Medizin und Hämatologie,
          <br /> FAMH Klinische Chemie, Hämatologie, Immunologie,
          <br />
          inkl. DNS-/RNS-Diagnostik <br />
          <br />
          ZENTRUM FÜR LABORMEDIZIN
          <br />
          Frohbergstrasse 3<br />
          Postfach 1217
          <br />
          CH-9001 St.Gallen <br />
          <br />
          wolfgang.korte@zlmsg.ch <br />
          Telefon +41 58 580 92 02
        </Paragraph>
        <LeadHeading>2. Erhebung und Bearbeitung von Personendaten</LeadHeading>
        <Paragraph>
          Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen
          unserer Geschäftsbeziehung mit unseren Kunden und anderen
          Geschäftspartnern von diesen und weiteren daran beteiligten Personen
          erhalten oder die wir beim Betrieb unserer Websites, Apps und weiteren
          Anwendungen von deren Nutzern erheben.
        </Paragraph>
        <LeadHeading>
          3. Zwecke der Datenbearbeitung und Rechtsgrundlagen
        </LeadHeading>
        <Paragraph>
          Wir verwenden die von uns erhobenen Personendaten in erster Linie, um
          unsere Verträge mit unseren Kunden und Geschäftspartnern
          abzuschliessen und abzuwickeln, sowie um unseren gesetzlichen
          Pflichten im In- und Ausland nachzukommen. Wenn Sie für einen solchen
          Kunden oder Geschäftspartner tätig sind, können Sie in dieser Funktion
          mit Ihren Personendaten natürlich ebenfalls davon betroffen sein.
          <br />
          <br /> Soweit Sie uns eine Einwilligung zur Bearbeitung Ihrer
          Personaldaten für bestimmte Zwecke erteilt haben, bearbeiten wir Ihre
          Personendaten im Rahmen und gestützt auf diese Einwilligung, soweit
          wir keine andere Rechtsgrundlage haben und wir eine solche benötigen.
          <br /> Eine erteilte Einwilligung kann jederzeit widerrufen werden,
          was jedoch keine Auswirkung auf bereits erfolgte Datenbearbeitungen
          hat.
        </Paragraph>
        <LeadHeading>
          4. Technologien, die mit der Nutzung unserer Website zusammenhängen
        </LeadHeading>
        <Paragraph>
          <b>Email</b>
          <br /> Unsere Website bietet Besuchern E-Mailadressen um eine
          unmittelbare Kommunikation mit uns zu ermöglichen. Wenn Sie per E-Mail
          mit uns in Kontakt treten, werden die von Ihnen übermittelten
          personenbezogenen Daten gespeichert. Die auf freiwilliger Basis
          übermittelten personenbezogenen Daten werden für die Bearbeitung und
          Kontaktaufnahme zu Ihnen gespeichert. Es findet keine Weitergabe
          dieser Daten an Dritte statt. Diese Daten werden nur erhoben um Ihr
          Anliegen zu bearbeiten.
          <br />
          <br />
          <b>Datenerfassung durch den Website-Hosting Anbieter</b>
          <br /> Unsere Hosting-Anbieter ist GoEast GmbH, Oberstrasse 222, 9014
          St.Gallen, Schweiz,{" "}
          <a href="https://www.goeast.ch/#start">GoEast.ch</a>
          <br />
          <br /> Die Webserver der GoEast GmbH erfassen mit jedem Aufruf dieser
          Website allgemeine Daten und Informationen. Diese werden in den
          Logfiles des Servers gespeichert. Erfasst werden u.a. der verwendete
          Browser und dessen Version, das Betriebssystem, die
          Referrer-Internetseite, die Unterwebseiten, das Datum und die Uhrzeit
          des Zugriffs, eine Internet-Protokoll-Adresse (IP), der
          Internet-Service-Provider (ISP) und andere Daten und Informationen,
          die der Abwehr von Gefahren im Falle von Systemangriffen dienen. Durch
          diese allgemeinen Daten und Informationen zieht GoEasts keine
          Rückschlüsse auf die Person. Vielmehr werden diese Informationen
          genutzt, um die Inhalte korrekt zu liefern, die Webseite zu
          optimieren, die Funktionsfähigkeit unserer IT Systeme zu gewährleisten
          und im Falle eines Cyberangriffs der Strafverfolgungsbehörde die
          nötigen Informationen bereitstellen zu können. Die Daten können
          statistisch ausgewertet werden, allerdings wird kein Personenbezug
          hergestellt. Die anonymen Daten der Server-Logdateien werden separat
          von allen durch eine Person angegebenen personenbezogenen Daten
          gespeichert.
        </Paragraph>
        <LeadHeading>
          5. Datenweitergabe und Datenübermittlung ins Ausland
        </LeadHeading>
        <Paragraph>
          Wir geben im Rahmen unserer geschäftlichen Aktivitäten und der Zwecke
          gemäss Ziff. 3, soweit erlaubt und es uns als angezeigt erscheint,
          auch Dritten bekannt wenn sie z.B. diese für uns bearbeiten.
        </Paragraph>
        <LeadHeading>6. Dauer der Aufbewahrung von Personendaten</LeadHeading>
        <Paragraph>
          Wir verarbeiten und speichern Ihre Personendaten, solange es für die
          Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst
          die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. also
          zum Beispiel für die Dauer der gesamten Geschäftsbeziehung (von der
          Anbahnung, Abwicklung bis zur Beendigung eines Vertrags) sowie darüber
          hinaus gemäss den gesetzlichen Aufbewahrungs- und
          Dokumentationspflichten. Dabei ist es möglich, dass Personendaten für
          die Zeit aufbewahrt werden, in der Ansprüche gegen unser Unternehmen
          geltend gemacht werden können und soweit wir anderweitig gesetzlich
          dazu verpflichtet sind oder berechtigte Geschäftsinteressen dies
          erfordern (z.B. für Beweis- und Dokumentationszwecke). Sobald Ihre
          Personendaten für die oben genannten Zwecke nicht mehr erforderlich
          sind, werden sie grundsätzlich und soweit möglich gelöscht oder
          anonymisiert. Für betriebliche Daten (z.B. Systemprotokolle, Logs),
          gelten grundsätzliche kürzere Aufbewahrungsfristen von zwölf Monaten
          oder weniger.
        </Paragraph>
        <LeadHeading>7. Datensicherheit</LeadHeading>
        <Paragraph>
          Wir treffen angemessene technische und organisatorische
          Sicherheitsvorkehrungen zum Schutz Ihrer Personendaten vor
          unberechtigtem Zugriff und Missbrauch wie etwa der Erlass von
          Weisungen, Schulungen[, IT- und Netzwerksicherheitslösungen,
          Zugangskontrollen und –beschränkungen, Verschlüsselung von
          Datenträgern und Übermittlungen, Pseudonymisierung, Kontrollen.
        </Paragraph>
        <LeadHeading>
          8. Pflicht zur Bereitstellung von Personendaten
        </LeadHeading>
        <Paragraph>
          Im Rahmen unserer Geschäftsbeziehung müssen Sie diejenigen
          Personendaten bereitstellen, die für die Aufnahme und Durchführung
          einer Geschäftsbeziehung und der Erfüllung der damit verbundenen
          vertraglichen Pflichten erforderlich sind (eine gesetzliche Pflicht,
          uns Daten bereitzustellen, haben Sie in der Regel nicht). Ohne diese
          Daten werden wir in der Regel nicht in der Lage sein, einen Vertrag
          mit Ihnen (oder der Stelle oder Person, die Sie vertreten) zu
          schliessen oder diesen abzuwickeln. Auch die Website kann nicht
          genutzt werden, wenn gewisse Angaben zur Sicherstellung des
          Datenverkehrs (wie z.B. IP-Adresse) nicht offengelegt werden.
        </Paragraph>
        <LeadHeading>9. Rechte der betroffenen Person</LeadHeading>
        <Paragraph>
          Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und
          soweit darin vorgesehen (wie etwa im Falle der DSGVO) das Recht auf
          Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der
          Datenbearbeitung und sonst dem Widerspruch gegen unsere
          Datenbearbeitungen sowie auf Herausgabe gewisser Personendaten zwecks
          Übertragung an eine andere Stelle (sog. Datenportabilität). Bitte
          beachten Sie aber, dass wir uns vorbehalten, unsererseits die
          gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn
          wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet
          sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf
          berufen dürfen) oder sie für die Geltendmachung von Ansprüchen
          benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab
          informieren. Über die Möglichkeit, Ihre Einwilligung zu widerrufen,
          haben wir bereits in Ziff. 3 informiert. Beachten Sie, dass die
          Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen
          kann und dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder
          Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig
          informieren, wo dies nicht bereits vertraglich geregelt ist. Die
          Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre
          Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre
          Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur
          Geltendmachung Ihrer Rechte können Sie uns unter der weiter oben
          angegeben angegebenen Adresse kontaktieren. Jede betroffene Person hat
          überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei
          der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. Die
          zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische
          Datenschutz- und Öffentlichkeitsbeauftragte
          (http://www.edoeb.admin.ch).
        </Paragraph>
        <LeadHeading>10. Änderungen</LeadHeading>
        <Paragraph>
          Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung
          anpassen. Es gilt die jeweils aktuelle, auf unserer Website
          publizierte Fassung. Soweit die Datenschutzerklärung Teil einer
          Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer
          Aktualisierung über die Änderung per E-Mail oder auf andere geeignete
          Weise informieren.
        </Paragraph>
      </LongText>
    </Page>
  </DefaultLayout>
);

export default Datenschutz;
